@import "./variables.scss";
//use fonts:
//font-family Open Sans
// - font weight: 400/regular, 700/bold
// itallic ** Only with 400/regular

//font-family Quicksand
// -font-weight: 700/bold ONLY

// Theme overwrite:
$theme-colors: (
  "primary": $primary,
  "danger": #ff4136,
  "info": $teal,
  "gray": $gray,
);

$info: $teal;

$body-bg: $main-bg;
$body-color: $black;

$logo-light: url('./images/Logo.png')

// // Used for removing the up/down arrows in a num field.
;
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@import "~bootstrap/scss/bootstrap.scss";

h1,
h2,
h3,
h4,
h5,
h6,
header {
  font-family: "Quicksand", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: bold;
}

body {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

body.dark-mode {
  background-color: $dark-mode-bg;
  color: $dark-mode-text;
  .nav-link {
    color: $gray !important;
  }
  .nav-link.active, .nav-link.active:hover{
    color: $dark-mode-text !important;
  }
  .nav-link:hover {
    color: $dark-mode-primary !important;
  }
  .componentWrapper {
    background-color: $dark-mode-component;
    border: 2px solid $dark-mode-text;
  }
  .sallie {
    background-color: $dark-mode-component;
    border: 2px solid $dark-mode-text;
  }
  .submitButton {
    color: $dark-mode-text;
    border: 2px solid $dark-mode-text;
  }
  .submitButton:hover {
    background-color: $dark-mode-primary;
  }
  .logo{
    width: 258px;
    height:100px;
  }
 

  #nav-border {
    border-bottom: 4px solid $dark-mode-primary !important;
  }
  
  .pillBtn {
    background-color: $dark-mode-primary; 
    color: $dark-mode-text;
  }
  .listSelector {
    background: $dark-mode-component;
  }
  .DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: $gray;
  }
  .DayPicker-Day--disabled {
    color: $dark-mode-disabled;
  }
}

.componentWrapper {
  background-color: white;
  border-radius: 5px;
  border: 2px solid $gray;
  // Set top and bottom padding equal to what Bootstrap providers for left and right padding on container spacing
  padding: 15px;
}

.sallie {
  display: flex;
  justify-content: center;
  text-align: left;
  margin: 10px;
  padding: 1rem;
  border-radius: 10px;
  background: white;
  border: 2px solid $gray;
}

.submitButtonBox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.progress {
  width: 35%;
  border-radius: 20px;
}
#nav-border {
  border-bottom: 4px solid $primary !important ;
}

.listSelector {
  background: white;
  border-radius: 5px;
  border: 2px solid $primary;
  padding: .5rem;
  margin: .25rem 0;
}
// see node-modules/react-day-picker/src/styles.css

.DayPicker {
  display: inline-block;
  width: 100%;
  font-size: 1rem !important;
}
@media (min-width: 576px) {
  .DayPicker {
    font-size: 1rem !important;
  }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  .DayPicker {
    font-size: 0.8rem !important;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .DayPicker {
    font-size: 1rem !important;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .DayPicker {
    font-size: 1rem !important;
  }
}
.required {
  color: red;
}
//remove red icon from invalid forms
.form-control.is-invalid {
  padding-right: inherit;
  background-image: none;
}
// stop shifting of form on invalid messages
.form-group {
  margin-bottom: 30px;
}
.invalid-feedback {
  height: 0;
}

.logo {
  max-height: inherit;
  object-fit: cover;
}


.brand-wrapper {
  max-height: 120px;
}

.brand-wrapper2 {
  max-height: 90px;
}

a {
  color: $teal;
}

.h6-label {
  @extend h6;
}

.accountSettingNav {
  width: 250px;
  height: 350px;
  border-radius: 5px;
  border: 2px solid $gray;
  padding: 1rem;
  margin: 15px 0px;
}

.nav-link {
  color: $black;
}

.nav-link:hover {
  color: $teal;
}

.goalForm {
  width: 700px;
  margin-top: 15px;
}

.goalList {
  display: flex;
  flex-direction: column;
  font-weight: normal;
}

.goalItem {
  margin-bottom: 5px;
}


.tab-content>.tab-pane {
  display: block;
  height: 0;
  overflow: hidden;
}
.tab-content>.tab-pane.active {
  height: auto;
} 

.helpImage {
  position: absolute;
  z-index: 100;
  margin-left: 5px;
  width: 450px;
  height: 300px;
}

.headacheForm {
  margin: 5px;
}

.editIcon {
  height: 15px;
  width: 15px;
}

.pdf-option-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}